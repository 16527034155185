import React, { useMemo, useState } from 'react';
import { PieChart, PieValueType } from '@mui/x-charts';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import { useTranslation } from 'react-i18next';

import { currencyFormatter } from 'utils/number';

const currency = 'GEL';

const mock = {
  total_payroll_cost: '1000.00',
  net_pay: '1000567.00',
  employee_pays: '23560.00',
  company_pays: '100000.00',
};

export const Chart = () => {
  const { t } = useTranslation();

  const [max, setMax] = useState(0);

  const legend = useMemo(() => {
    const totalSum = Object.values(mock).reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue),
      0
    );
    const lengths: number[] = [];
    const result: MakeOptional<PieValueType, 'id'>[] = Object.entries(mock).map(
      ([key, value], index) => {
        const percent = Number(value) / (totalSum / 100);
        const label = currencyFormatter({ amount: value, currency });
        const subtitle = t(`payroll.previous_cost.${key}`);

        if (label!.length - 10 > 0) lengths.push(label!.length - 10);

        return {
          id: index,
          value: percent,
          label: `${label}\n ${subtitle}`,
        };
      }
    );
    setMax(lengths.length ? Math.max(...lengths) : 0);
    return result;
  }, [t]);

  return (
    <div>
      <PieChart
        series={[
          {
            data: legend,
            innerRadius: 50,
          },
        ]}
        sx={{
          rect: {
            ry: '50%',
            y: '-15',
          },
          text: {
            textAnchor: 'end',
          },
          '.MuiChartsLegend-root': {
            transform: `translate(${280 - max * 8}px, 0)`,
            'g:nth-child(1)': {
              transform: 'translate(0px, 25px)',
            },
            'g:nth-child(2)': {
              transform: 'translate(0px, 75px)',
            },
            'g:nth-child(3)': {
              transform: 'translate(0px, 125px)',
            },
            'g:nth-child(4)': {
              transform: 'translate(0px, 175px)',
            },
          },
          'text tspan:not(:first-child)': {
            fontSize: 10,
            fontFamily: 'Aspira Regular',
            fill: '#9F9F9F',
          },
        }}
        margin={{
          left: -94,
        }}
        slotProps={{
          legend: {
            labelStyle: {
              fontSize: 15,
              fontFamily: 'Aspira Demi',
            },
            itemGap: 20,
            markGap: 100 + max * 8,
            itemMarkHeight: 14,
            itemMarkWidth: 14,
          },
        }}
        tooltip={{ trigger: 'none' }}
        width={400}
        height={200}
      />
    </div>
  );
};
