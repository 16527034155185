import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Link as MUILink } from '@mui/material';
import styled from 'styled-components';

import { RunPayrollModal } from './RunPayrollModal';

export const RunPayroll = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Container>
        <Action fullWidth variant={'contained'} onClick={() => setOpen(true)}>
          {t('payroll.run_block.run_regular_payroll').toLocaleUpperCase()}
        </Action>
        <Link>{t('payroll.run_block.run_offcycle_payroll')}</Link>
        <Link>{t('payroll.run_block.advance_payment')}</Link>
      </Container>
      {open && <RunPayrollModal onClose={() => setOpen(false)} />}
    </>
  );
};

const Link = styled(MUILink)`
  color: var(--orange);
  text-decoration-color: var(--orange);
  font-size: 11.5px;
  font-family: 'Aspira Wide Demi', 'FiraGO Medium';
  cursor: pointer;
`;

const Action = styled(Button)`
  background-color: #339966 !important;
  &:hover {
    background-color: #236b47 !important;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding-bottom: 25px;
`;
