import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { PageHeaderTitle } from 'components/DesignUIComponents';
import { Section } from './Section';
import { PayHistory } from './PayHistory';
import { RunPayroll } from './RunPayroll';
import { Chart } from './Chart';

export const Payroll = () => {
  const { t } = useTranslation();

  return (
    <div>
      <PageHeaderTitle title={t('headers.authorized.payroll')} />
      <Inner>
        <LeftBlock>
          <Section title={t('payroll.run_payroll')}>
            <RunPayroll />
          </Section>
          <Section title={t('payroll.previous_cost.title')}>
            <Chart />
          </Section>
        </LeftBlock>
        <Section style={{ gridColumn: '2 / 5' }} title={t('payroll.history_block.title')}>
          <PayHistory />
        </Section>
      </Inner>
    </div>
  );
};

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 12px 60px 0;
  gap: 25px;
  height: calc(100% - 118px);
`;

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
