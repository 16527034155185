import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridColumns,
  GridFilterModel,
  gridFilterModelSelector,
  GridInitialState,
  GridRowParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { uniq } from 'lodash';
import { enUS, ka } from 'date-fns/locale';
import format from 'date-fns/format';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import DataGrid from 'components/DataLists/DataGrid';
import SelectDropdown from 'components/Dropdowns/SelectDropdown';
import useQueryCustom from 'hooks/useQueryCustom';
import { PaymentDocuments } from 'types';
import { getPeriod } from 'utils/date';
import { Actions } from './Actions';
import { currencyFormatter } from 'utils/number';

type SelectOption = {
  id: string | number;
  name: string;
};

type Filters = {
  types: SelectOption[];
  periods: SelectOption[];
  statuses: SelectOption[];
};

type CustomToolbarProps = {
  filters: Filters;
};

const initialState: GridInitialState = {
  sorting: {
    sortModel: [{ field: 'payment_date', sort: 'desc' }],
  },
};

const CustomToolbar = ({ filters }: CustomToolbarProps) => {
  const apiRef = useGridApiContext();
  const gridFilterModelItems: GridFilterModel['items'] = gridFilterModelSelector(apiRef).items;

  const typeSelectValue = useMemo(() => {
    const filterGridValue = gridFilterModelItems.find((e) => e.columnField === 'type')?.value;
    const result: SelectOption =
      filters.types.find((e) => e.name === filterGridValue) || filters.types[0];
    return result;
  }, [filters.types, gridFilterModelItems]);

  const periodSelectValue = useMemo(() => {
    const filterGridValue = gridFilterModelItems.find((e) => e.columnField === 'period')?.value;
    const result: SelectOption =
      filters.periods.find((e) => e.name === filterGridValue) || filters.periods[0];
    return result;
  }, [filters.periods, gridFilterModelItems]);

  const statusSelectValue = useMemo(() => {
    const filterGridValue = gridFilterModelItems.find((e) => e.columnField === 'status')?.value;
    const result: SelectOption =
      filters.statuses.find((e) => e.name === filterGridValue) || filters.statuses[0];
    return result;
  }, [filters.statuses, gridFilterModelItems]);

  const onChangeFilter = (value: SelectOption, type: 'type' | 'status' | 'period') => {
    if (value.id) {
      apiRef.current.upsertFilterItems([
        ...gridFilterModelItems.filter((e) => e.columnField !== type),
        {
          columnField: type,
          value: value.name,
          operatorValue: 'equals',
          id: `fltr_${type}`,
        },
      ]);
    } else {
      apiRef.current.deleteFilterItem({ columnField: type, id: `fltr_${type}` });
    }
  };

  return (
    <FiltersContainer>
      <SelectDropdown
        size={'small'}
        value={typeSelectValue}
        onChange={(_, value) => onChangeFilter(value as SelectOption, 'type')}
        disableClearable
        options={filters.types}
        sx={{ width: 220 }}
      />
      <SelectDropdown
        size={'small'}
        value={periodSelectValue}
        onChange={(_, value) => onChangeFilter(value as SelectOption, 'period')}
        disableClearable
        options={filters.periods}
        sx={{ width: 200 }}
      />
      <SelectDropdown
        size={'small'}
        value={statusSelectValue}
        onChange={(_, value) => onChangeFilter(value as SelectOption, 'status')}
        disableClearable
        options={filters.statuses}
        sx={{ width: 150 }}
      />
    </FiltersContainer>
  );
};

export const PayHistory = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();

  const [filters, setFilters] = useState<Filters>({
    types: [{ id: 0, name: t('payroll.history_block.select.type') }],
    periods: [{ id: 0, name: t('payroll.history_block.select.period') }],
    statuses: [{ id: 0, name: t('payroll.history_block.select.status') }],
  });

  const { data = [], isLoading } = useQueryCustom<PaymentDocuments, { errors: string[] }>(
    ['get_payment_documents'],
    {
      endpoint: '/payment_document',
      options: {
        method: 'get',
      },
    },
    {
      onSuccess: (data) => {
        const types = uniq(data.map((e) => e.payment_document_type.name));
        const statuses = uniq(data.map((e) => e.payment_document_status.name));
        const periods = uniq(data.map((e) => getPeriod(e.period_start, e.period_end)));
        setFilters((prev) => ({
          types: [
            prev.types[0],
            ...types.map((name, index) => ({
              id: index + 1,
              name,
            })),
          ],
          periods: [
            prev.periods[0],
            ...periods.map((name, index) => ({
              id: index + 1,
              name,
            })),
          ],
          statuses: [
            prev.statuses[0],
            ...statuses.map((name, index) => ({
              id: index + 1,
              name,
            })),
          ],
        }));
      },
      refetchOnWindowFocus: false,
    }
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'payment_date',
        type: 'date',
        valueFormatter: ({ value }) =>
          format(new Date(value), 'd MMM, yyyy', {
            locale: i18n.language === 'ka' ? ka : enUS,
          }),
        headerName: t('payroll.pay_date'),
        flex: 1,
      },
      {
        field: 'type',
        headerName: t('globaly.type'),
        valueGetter: ({ row }) => row.payment_document_type.name,
        flex: 1,
      },
      {
        field: 'period',
        headerName: t('payroll.history_block.pay_period'),
        valueGetter: ({ row }) => getPeriod(row.period_start, row.period_end),
        flex: 1,
      },
      {
        field: 'total_payroll_cost',
        type: 'number',
        valueGetter: ({ row, value }) =>
          currencyFormatter({ currency: row.currency.code, amount: value || 0 }),
        headerName: t('payroll.history_block.total_cost'),
        flex: 1,
      },
      {
        field: 'net_pay',
        type: 'number',
        valueGetter: ({ row, value }) =>
          currencyFormatter({ currency: row.currency.code, amount: value || 0 }),
        headerName: t('payroll.history_block.net_pay'),
        flex: 1,
      },
      {
        field: 'status',
        headerName: t('globaly.status'),
        renderCell: ({ value, row }) => (
          <Status data-status={`${row.payment_document_status.id}`}>
            <span className="dot"></span>
            {value}
          </Status>
        ),
        valueGetter: ({ row }) => row.payment_document_status.name,
        flex: 1,
      },
      {
        field: 'action',
        type: 'actions',
        headerName: t('globaly.action'),
        renderHeader: () => <></>,
        getActions: (params: GridRowParams) => [
          <Actions onView={() => history.push(`/payroll/${params.id}`, { step: 3 })} />,
        ],
      },
    ],
    [history, i18n.language, t]
  );

  return (
    <DataGrid
      name={'payment_documents'}
      saveGridState
      rows={data}
      columns={columns}
      initialState={initialState}
      loading={isLoading}
      disableQuickFilter
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      noRowsOverlayText={t('payroll.history_block.no_payment')}
      components={{
        Toolbar: CustomToolbar,
      }}
      componentsProps={{
        toolbar: {
          filters,
        },
      }}
    />
  );
};

const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  & > .MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiInputBase-root {
        & > div {
          button {
            padding: 3px 3px;
          }
        }
      }
    }
  }
`;

const Status = styled.div`
  color: var(--orange);
  .dot {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;
    display: inline-block;
    background: var(--orange);
  }

  &[data-status='approved'] {
    color: var(--green);
    .dot {
      background: var(--green);
    }
  }
  &[data-status='cancelled'] {
    color: var(--dark-gray);
    .dot {
      background: var(--dark-gray);
    }
  }
`;
