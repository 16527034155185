import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  GridEventListener,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';

type EditCellProps = Pick<GridRenderEditCellParams, 'value'> & {
  onEditStop: (value: number) => void;
  onlyNegative?: boolean;
};

export const EditCell = ({ value, onEditStop, onlyNegative }: EditCellProps): ReactElement => {
  const apiRef = useGridApiContext();
  const [editValue, setEditValue] = useState<number>(value);

  const handleEditStop: GridEventListener<'cellEditStop'> = useCallback(
    (params) => {
      if (params.value === editValue) return false;

      onEditStop(editValue);
    },
    [editValue, onEditStop]
  );

  useEffect(() => {
    const unregister = apiRef.current.subscribeEvent('cellEditStop', handleEditStop);
    return () => unregister();
  }, [apiRef, handleEditStop]);

  return (
    <CustomNumericFormat
      autoFocus
      onValueChange={(values) => {
        const value = Number(values.value || 0);
        setEditValue(value * (onlyNegative && value > 0 ? -1 : 1));
      }}
      value={editValue}
      decimalSeparator="."
      decimalScale={2}
      valueIsNumericString
    />
  );
};

const CustomNumericFormat = styled(NumericFormat)`
  width: 100%;
  margin: 0 9px;
  padding-right: 10px;
  text-align: end;
  border: none;
  background: var(--light-purple);
  height: 40px;
`;
