import React, { CSSProperties, ReactElement } from 'react';
import styled from 'styled-components';

type SectionProps = {
  amount?: string;
  children: ReactElement;
  style?: CSSProperties;
  title: string;
};

export const Section = ({ amount, children, style, title }: SectionProps) => {
  return (
    <Container style={style}>
      <Header>
        <span>{title}</span>
        {amount && <span>{amount}</span>}
      </Header>
      <Body>{children}</Body>
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid var(--meddium-green);
  border-radius: 6px;
  overflow: auto;
`;

const Header = styled.div`
  background: var(--meddium-green);
  display: flex;
  justify-content: space-between;
  padding: 13px;
  font-size: 14px;
  line-height: 1;
`;

const Body = styled.div`
  padding: 19px 15px;
  height: 100%;
  max-height: calc(100% - 40px);
`;
