import React from 'react';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as MagnifierIcon } from 'assets/svg/actions_circle/magnifier.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/actions_circle/x.svg';

type ActionsProps = {
  onView: () => void;
};

export const Actions = ({ onView }: ActionsProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex' }}>
      <GridActionsCellItem
        className={'actionButton'}
        icon={
          <Tooltip title={t('timeOff.view')} disableInteractive arrow>
            <StyledActionIcon as={MagnifierIcon} />
          </Tooltip>
        }
        label={t('timeOff.view')}
        onClick={onView}
        sx={{
          padding: '2px',
          '&:hover': { backgroundColor: 'transparent' },
        }}
      />
      {false && (
        <GridActionsCellItem
          className={'actionButton'}
          icon={
            <Tooltip title={t('timeOff.deny')} disableInteractive arrow>
              <StyledActionIcon as={CloseIcon} />
            </Tooltip>
          }
          label={t('timeOff.deny')}
          onClick={() => {}}
          sx={{ padding: '2px', '&:hover': { backgroundColor: 'transparent' } }}
        />
      )}
    </div>
  );
};

const StyledActionIcon = styled.svg`
  width: 22px;
  height: 22px;
  &:hover {
    circle {
      fill: #396;
    }
    path:not(#Vector, #Vector-2) {
      fill: #fff;
    }
    #Vector,
    #Vector-2 {
      stroke: #fff;
    }
  }
`;
