import format from 'date-fns/format';
import { enUS, ka } from 'date-fns/locale';
import i18n from 'i18n';

export const getPeriod = (periodStart: string, periodEnd: string) => {
  const start = format(new Date(periodStart), 'd MMM', {
    locale: i18n.language === 'ka' ? ka : enUS,
  });
  const end = format(new Date(periodEnd), 'd MMM, yyyy', {
    locale: i18n.language === 'ka' ? ka : enUS,
  });
  const result: string = `${start} - ${end}`;
  return result;
};
