import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from 'assets/svg/sliderArrow.svg';

import { Earnings } from './Tables/Earnings';
import { Benefits } from './Tables/Benefits';
import { Summary } from './Tables/Summary';
import Steps from 'components/Steps';

const flow: ReactElement[] = [<Earnings />, <Benefits />, <Summary />];

export const PaymentDocument = () => {
  const { t } = useTranslation();
  const history = useHistory<{ step: number }>();

  const [activeStep, setActiveStep] = useState(history.location.state?.step || 1);

  return (
    <Container>
      <Wrapper>
        <div>
          <Steps
            activeStep={activeStep}
            items={Object.values<{ title: string }>(
              t('payroll.payment_document.steps', { returnObjects: true })
            ).map((value, index) => ({
              label: value.title,
              step: index + 1,
              title: index + 1,
            }))}
          />
        </div>
        {flow[activeStep - 1]}
      </Wrapper>
      <Footer style={{ gap: activeStep === 1 ? 10 : 70 }}>
        <Button className={'close'} onClick={history.goBack} variant={'outlined'}>
          {t('payroll.payment_document.close')}
        </Button>
        <div style={{ display: 'flex', gap: 10 }}>
          {activeStep > 1 && (
            <Button
              className={'prev'}
              onClick={() => setActiveStep((prev) => prev - 1)}
              variant={'text'}
              startIcon={<ArrowIcon />}
            >
              {t('payroll.payment_document.previous_step')}
            </Button>
          )}
          {activeStep < flow.length ? (
            <Button
              onClick={() => setActiveStep((prev) => prev + 1)}
              variant={'contained'}
              endIcon={<ArrowIcon />}
            >
              {t('payroll.payment_document.continue')}
            </Button>
          ) : (
            <Button onClick={() => {}} variant={'contained'}>
              {t('payroll.payment_document.submit')}
            </Button>
          )}
        </div>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  gap: 13px;
  padding: 40px 30px 66px 0;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 40px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  height: 50px;
  border-top: 1px solid var(--light-gray);

  button {
    width: 152px;
    justify-content: space-between;

    &:not(.close, .prev) {
      background-color: #339966 !important;
      &:hover {
        background-color: #236b47 !important;
      }
    }
  }
  .close {
    justify-content: center;
  }
  .prev {
    padding: 6px 16px;
    svg {
      transform: rotate(180deg);
      path {
        fill: var(--green);
      }
    }

    background-color: transparent !important;
    &:hover {
      background-color: #dceee5 !important;
    }
  }
`;
